import React, { useEffect,useState, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRealisasiBelanjaOPD, getPendapatanDaerah, getRealisasiPbjProses, getDataHibah } from '../../../redux/ducks/home/actions';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Pie } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import YearSelector from '../../components/YearSelector';
import VisitorsCounter from '../../components/VisitorsCounter';
import LaporIcon from '../../components/LaporIcon';

ChartJS.register(ArcElement, Tooltip, Legend);

const Home2 = ({ getRealisasiBelanjaOPD, getPendapatanDaerah, getRealisasiPbjProses, getDataHibah, homeState }) => {
  let [isOpen, setIsOpen] = useState(true)

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }
  const navigate = useNavigate();
  const { yearconfig } = useParams()
  useEffect(() => { getDataHibah(yearconfig); }, [yearconfig]);
  useEffect(() => { getPendapatanDaerah(yearconfig); }, [yearconfig])
  useEffect(() => { getRealisasiPbjProses(yearconfig); }, [yearconfig])
  useEffect(() => { getRealisasiBelanjaOPD(yearconfig); }, [yearconfig])
  
  const dataPendapatan = {
    labels: [`Realisasi : ${numberFormatter(homeState.pendapatanDaerah?.realisasi)}`, `Target : ${numberFormatter(homeState.pendapatanDaerah?.target)}`],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState.pendapatanDaerah?.persentase, (100 - homeState.pendapatanDaerah.persentase)],
        backgroundColor: ['#819E43', '#B5DC62'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const dataRealisasiBelanjaOpd = {
    labels: [`Realisasi : ${numberFormatter(homeState.realisasiBelanjaOPD?.realisasi)}`, `Target : ${numberFormatter(homeState.realisasiBelanjaOPD?.target)}`],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState.realisasiBelanjaOPD?.persentase, (100 - homeState.realisasiBelanjaOPD.persentase)],
        backgroundColor: ['#FF6F05', '#FFB883'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const _toBappendDetail = () => navigate(`/tahun/${yearconfig}/bappenda/detail-pendapatan`);

  const _toSimbangdaDetail = () => navigate(`/tahun/${yearconfig}/simbangda/detail-belanja-opd`);

  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: false,
      goTo: '',
    },
  ];

  const checkAnd = (title) => {
    return title.replace('&', 'dan');
  };
  const initPlugins = [{
      beforeDraw: function(chart) {
        
      }
  }]

  const [loadingPage, setLoadingPage] = useState(true)
  useEffect(() => {
    let loadingSet = false
    if(homeState.pendapatanDaerah.loadingGet && homeState.realisasiBelanjaOPD.loadingGet && homeState.realisasiPbjProses.loadingGet && homeState.dataHibah.loadingGet) loadingSet = true
    setLoadingPage(loadingSet)
  },[homeState.pendapatanDaerah.loadingGet, homeState.realisasiBelanjaOPD.loadingGet, homeState.realisasiPbjProses.loadingGet, homeState.dataHibah.loadingGet])
  let [popUp, setPopUp] = useState(false)
  const popUpToggle = () => setPopUp(!popUp)
  return (
    <Layout loading={loadingPage} breadCrumbsData={breadCrumbsData}>
      <div onClick={popUpToggle} className={`w-screen h-screen fixed top-0 left-0 z-[99999] bg-gray-500/50 flex flex-col justify-center items-center ${popUp ? 'hidden' : ''}`}>
        <div className="flex justify-center items-center animate__animated animate__zoomIn relative md:w-3/5 sm:w-2/3 w-full">
          <img src="/img/dashboard-winning-awards-small.png" alt="loading" className="w-full" />
            <div className="absolute top-10 right-10 flex flex-col justify-center items-center">
              <button 
                onClick={popUpToggle}
                className="text-gray-800 font-semibold py-1 px-2 text-xs border border-gray-800 rounded-full shadow"
              >
                X
              </button>
            </div>
        </div>
        
      </div>
      <div className="w-full  rounded-lg flex gap-5 flex-col lg:flex-row xl:flex-row mb-3">
          <YearSelector />
      </div>
      
      <div className="w-full  rounded-lg flex gap-5 flex-col lg:flex-row xl:flex-row">
        
        <div
          onClick={_toBappendDetail}
          className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 cursor-pointer w-full h-1/2"
        >
          <div className="flex flex-col md:flex-row lg:flex-row justify-between px-2 ">
            <div className=" lg:w-3/5 xl:w-3/5">
              <h1 className="text-2xl text-primaryGreen font-bold text-left mb-5">Pendapatan Daerah Tahun {yearconfig}</h1>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row ">
                <span className="w-1/3">Target</span>
                <span>
                  : {numberFormatter(homeState.pendapatanDaerah.target)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.target} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row mt-2">
                <span className="w-1/3">Realisasi</span>
                <span>
                  : {numberFormatter(homeState.pendapatanDaerah.realisasi)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.realisasi} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row mt-2">
                <span className="w-1/3">Persentase</span>
                <span>: {numberFormatter(homeState.pendapatanDaerah.persentase)} %</span>
              </h2>
              {/* <h2 className="text-md text-black flex flex-row mt-10">
                <span className="w-1/3">Terakhir Update</span>
                <span>: {homeState.realisasiBelanjaOPD.last_update}</span>
              </h2> */}
              <div className="text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1 mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-gray-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-xs text-gray-600 mt-1 pl-1">
                  terakhir update : {homeState.pendapatanDaerah.last_update}
                </span>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col gap-2">
                <div className="flex">
                  <div className="h-4 w-4 rounded-full bg-[#819E43]"></div>
                  <p className="text-xs pl-1 italic">Realisasi</p>
                </div>
                <div className="flex">
                  <div className="h-4 w-4 rounded-full bg-[#B5DC62]"></div>
                  <p className="text-xs pl-1 italic">Target</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-44 lg:h-44  xl:w-44 xl:h-44 ">
              <Pie
                data={dataPendapatan}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  color: 'black',
                  plugins: {
                    legend: {
                      labels: {
                        font: {
                          size: 14,
                          // weight: 'bold',
                        },
                      },
                    },
                    datalabels: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                          label: function (tooltipItem, data) {
                              return '';
                          }
                      }
                    },
                    legend: false
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={_toSimbangdaDetail}
          className="bg-white rounded-lg drop-shadow-2xl p-5 cursor-pointer h-1/2 w-full"
        >
          <div className="flex flex-col md:flex-row lg:flex-row justify-between px-2 ">
            <div className=" lg:w-3/5 xl:w-3/5">
              <h1 className="text-2xl text-primaryOrange font-bold text-left mb-5">Realisasi Belanja OPD Tahun {yearconfig}</h1>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row ">
                <span className="w-1/3">Pagu</span>
                <span>
                  : {numberFormatter(homeState.realisasiBelanjaOPD.target)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.target} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row mt-2">
                <span className="w-1/3">Realisasi</span>
                <span>
                  : {numberFormatter(homeState.realisasiBelanjaOPD.realisasi)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.realisasi} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-lg xl:text-lg text-sm text-black flex flex-row mt-2">
                <span className="w-1/3">Persentase</span>
                <span>: {numberFormatter(homeState.realisasiBelanjaOPD.persentase)} %</span>
              </h2>
              {/* <h2 className="text-md text-black flex flex-row mt-10">
                <span className="w-1/3">Terakhir Update</span>
                <span>: {homeState.realisasiBelanjaOPD.last_update}</span>
              </h2> */}
              <div className="text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row mb-1 mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-gray-500"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-xs text-gray-600 mt-1 pl-1">
                  terakhir update : {homeState.realisasiBelanjaOPD.last_update}
                </span>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="flex flex-col gap-2">
                <div className="flex">
                  <div className="h-4 w-4 rounded-full bg-primaryOrange"></div>
                  <p className="text-xs pl-1 italic">Realisasi</p>
                </div>
                <div className="flex">
                  <div className="h-4 w-4 rounded-full bg-orange-300"></div>
                  <p className="text-xs pl-1 italic">Pagu</p>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-44 lg:h-44  xl:w-44 xl:h-44 ">
              <Pie
                data={dataRealisasiBelanjaOpd}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  color: 'black',
                  plugins: {
                    datalabels: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                          label: function (tooltipItem, data) {
                              return null;
                          }
                      }
                    },
                    legend: false,
                  },
                }}
                
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mt-5 rounded-lg ${
          homeState.realisasiPbjProses.data.length === 0 ? `hidden` : `animate__animated animate__fadeIn`
        }`}
      >
        <div className="grid grid-rows-1 lg:grid-cols-4 xl:grid-cols-4 2xl:gird-cols-4 gap-2">
          {homeState.realisasiPbjProses.data.map((items, keys) => {
            return (
              <div
                key={keys}
                onClick={() => {
                  if (items.type !== 0) {
                    navigate(`/tahun/${yearconfig}/sipedal/detail-sipedal-opd?type=${items.type}&typeName=${checkAnd(items.title)}`);
                  }
                }}
                className="flex flex-col p-2 bg-white cursor-pointer rounded-xl drop-shadow-2xl animate__animated animate__fadeIn sm:mb-10 mb-3"
              >
                <div className="mb-1">
                  <div className={`w-full  ${items.bg} rounded-md p-1`}>
                    <h1 className="text-sm font-bold text-white text-center">{items.title}</h1>
                  </div>
                </div>
                <div>
                  <ul>
                    {items.data.map((childItems, keysChild) => {
                      let isEven = keysChild % 2 === 0 ? true : false; // isGenap
                      return (
                        <li key={keysChild} className={'py-1'}>
                          <div className="border-b-2 border-gray-200">
                            <div
                              className={`flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 ${
                                isEven ? `border-primaryGreen` : `border-primaryOrange`
                              } `}
                            >
                              <div className="w-[60%] font-medium text-sm">{childItems.title}</div>
                              <div className="space-y-2">
                                <span
                                  className={` ${
                                    isEven ? `bg-green-100` : `bg-secondaryOrange`
                                  } px-1 text-right rounded-lg float-right text-sm`}
                                >
                                  {numberFormatter(childItems.value)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="h-full flex flex-row justify-end mt-1">
                  <p className="font-bold text-xs text-gray-400 text-right">terakhir update : {items.last_update}</p>
                </div>
              </div>
            );
          })}
          <div 
            onClick={() => navigate(`/tahun/${yearconfig}/hibah/opd-pemberi-hibah`)}
            className="flex flex-col p-2 bg-white cursor-pointer rounded-xl drop-shadow-2xl animate__animated animate__fadeIn mb-10">
                <div className="mb-1">
                  <div className="w-full bg-primaryGreen rounded-md p-1">
                    <h1 className="text-sm font-bold text-white text-center">Hibah</h1>
                  </div>
                </div>
                <div>
                  <ul>
                    <li className={'py-1'}>
                      <div className="border-b-2 border-gray-200">
                        <div className="flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 border-primaryGreen">
                          <div className="w-[60%] font-medium text-sm">Penerima Hibah</div>
                          <div className="space-y-2">
                            <span
                              className="bg-green-100 px-1 text-right rounded-lg float-right text-sm"
                            >
                              {numberFormatter(homeState.dataHibah.data.jumlah)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className={'py-1'}>
                      <div className="border-b-2 border-gray-200">
                        <div
                          className="flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 border-primaryOrange">
                          <div className="w-[60%] font-medium text-sm">Nilai</div>
                          <div className="space-y-2">
                            <span
                              className="bg-secondaryOrange px-1 text-right rounded-lg float-right text-sm">
                              {numberFormatter(homeState.dataHibah.data.nilai)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className={'py-1'}>
                      <div className="border-b-2 border-gray-200">
                        <div className="flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 border-primaryGreen">
                          <div className="w-[60%] font-medium text-sm">Belum Proses</div>
                          <div className="space-y-2">
                            <span
                              className="bg-green-100 px-1 text-right rounded-lg float-right text-sm"
                            >
                              {numberFormatter(homeState.dataHibah.data.belum_proses)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className={'py-1'}>
                      <div className="border-b-2 border-gray-200">
                        <div
                          className="flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 border-primaryOrange">
                          <div className="w-[60%] font-medium text-sm">Proses Pelaksanaan</div>
                          <div className="space-y-2">
                            <span
                              className="bg-secondaryOrange px-1 text-right rounded-lg float-right text-sm">
                              {numberFormatter(homeState.dataHibah.data.proses)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className={'py-1'}>
                      <div className="border-b-2 border-gray-200">
                        <div className="flex flex-col justify-between lg:flex-row xl:flex-row border-l-4 px-2 py-1 border-primaryGreen">
                          <div className="w-[60%] font-medium text-sm">Selesai Pelaksanaan</div>
                          <div className="space-y-2">
                            <span
                              className="bg-green-100 px-1 text-right rounded-lg float-right text-sm"
                            >
                              {numberFormatter(homeState.dataHibah.data.selesai)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="h-full flex flex-row justify-end items-end mt-1">
                  <p className="font-bold text-xs text-gray-400 text-right">terakhir update : {homeState.dataHibah.last_update}</p>
                </div>
              </div>
        </div>
        
        <div className="block md:hidden fixed bottom-[2.6rem] left-14">
            <LaporIcon />
          </div>
        <div className="flex w-full justify-end fixed bottom-12 right-2">
          <VisitorsCounter />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  homeState: state.homeState,
});

const mapDispatchToProps = {
  getRealisasiBelanjaOPD,
  getPendapatanDaerah,
  getRealisasiPbjProses,
  getDataHibah
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Home2);
